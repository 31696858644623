import React from 'react'
import { Row, Col } from 'components/grid'

interface Props {
	year: string
	focus: string
	focusText: string
	list?: JSX.Element[]
	handleModalContent: (type: string, title: string) => void
}

export default function TimelineCard({ year, focus, focusText, list, handleModalContent }: Props) {
	return (
		<Row className="arc-gradient-medium-reverse mb-4 py-6 px-10 lg:h-60 lg:px-0 lg:py-0" overflow={false}>
			<Col width="w-full lg:w-1/3" className="relative mb-4 lg:mb-0">
				<div className="flex h-full w-full items-center justify-start lg:justify-center">
					<span className="text-4xl font-semibold text-ab-100">Year {year}</span>
				</div>
				<div className={`${year === '1' ? 'h-[300px]' : 'h-64'} absolute -bottom-4 hidden w-3 bg-ab-100 lg:right-10 lg:block xl:right-16`}></div>
				<div className="absolute hidden h-8 w-8 rounded-full bg-ab-100 lg:right-[30px] lg:block xl:right-[54px]" style={{ top: 104 }}></div>
				{year === '5' && (
					<div className="absolute -bottom-8 hidden h-0 w-0 border-l-[20px] border-r-[20px] border-t-[20px] border-l-transparent border-r-transparent border-t-ab-100 lg:right-[26px] lg:block xl:right-[50px]"></div>
				)}
			</Col>
			<Col width="w-full lg:w-1/3" className="mb-6 lg:mb-0">
				<div className="flex h-full w-full  items-center justify-start lg:justify-center">
					<div>
						<span className="mb-0 block text-2xl font-semibold lg:mb-2 lg:text-4xl">{focus}</span>
						<span className="block text-lg lg:text-xl">{focusText}</span>
					</div>
				</div>
			</Col>
			<Col width="w-full lg:w-1/3">
				<div className="flex h-full w-full  items-center justify-start lg:justify-center">
					<div className="pl-4 lg:pl-0">
						{list}
						<ul className="w-full list-disc">
							<li className="mb-1">
								<span className="font-semibold">Literacy Operating System Year {year}</span>
								<ul className="list-circle pl-5">
									<li>
										<button onClick={() => handleModalContent('gif', 'SchoolPace Connect')}>
											<span className="flex items-center gap-2">
												SchoolPace Connect
												<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1.5}>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														fill="currentColor"
														d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
													/>
													<path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
												</svg>
											</span>
										</button>
									</li>
									<li>
										<button onClick={() => handleModalContent('video', 'ARC Core Professional Development')}>
											<span className="flex items-center gap-2">
												School-based Professional Learning
												<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1.5}>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														fill="currentColor"
														d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
													/>
													<path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
												</svg>
											</span>
										</button>
									</li>
									{year === '3' || year == '4' || year === '5' ? (
										<li>
											<button onClick={() => handleModalContent('learningLab', 'Learning Lab')}>
												<span className="flex items-center gap-2">
													Learning Lab
													<svg
														xmlns="http://www.w3.org/2000/svg"
														className="h-5 w-5"
														fill="none"
														viewBox="0 0 24 24"
														stroke="currentColor"
														strokeWidth={1.5}
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															fill="currentColor"
															d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
														/>
														<path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
													</svg>
												</span>
											</button>
										</li>
									) : null}
									<li>
										<button onClick={() => handleModalContent('video', 'Leadership Learning Series Promo')}>
											<span className="flex items-center gap-2">
												Leadership Learning Series
												<svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1.5}>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														fill="currentColor"
														d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
													/>
													<path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
												</svg>
											</span>
										</button>
									</li>
									{/* {year !== '1' && (
										<li>
											<button onClick={() => handleModalContent('arcUniversity', `ARC University${year === '2' ? ' for New Teachers' : ''}`)}>
												<span className="flex items-center gap-2">
													ARC University{year === '2' && ' for New Teachers'}
													<svg
														xmlns="http://www.w3.org/2000/svg"
														className="h-5 w-5"
														fill="none"
														viewBox="0 0 24 24"
														stroke="currentColor"
														strokeWidth={1.5}
													>
														<path
															strokeLinecap="round"
															strokeLinejoin="round"
															fill="currentColor"
															d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
														/>
														<path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
													</svg>
												</span>
											</button>
										</li>
									)} */}
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</Col>
		</Row>
	)
}
