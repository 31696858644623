import React from 'react'

interface Props {
	src: string
	title: string
	lazy?: boolean
	round?: boolean
}

const VideoEmbed = ({ src, title, lazy = true, round }: Props) => {
	return (
		<div className="bg-slate-100">
			<iframe
				loading={lazy ? 'lazy' : 'eager'}
				title={title}
				src={`${src}?background=1`}
				allow="autoplay"
				allowFullScreen
				className={`aspect-video w-full ${round ? 'rounded-xl' : ''}`}
			/>
		</div>
	)
}

export default VideoEmbed
